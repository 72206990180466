$(document).ready(function() {
    $('.closeModal').click(function(){
        $('body').removeClass('locked');
        $('#modal').removeClass('active');
    });

    $('.jsModal').click(function(){
        $('body').addClass('locked');
        $('#modal').addClass('active');
    });

    $('.accordeon').click(function(){
        if($(this).hasClass('active')){
            $(this).removeClass('active');
        }else{
            $(this).addClass('active');
        }
    });

    $('.arrow').click(function(){
        $('html, body').animate({
           scrollTop: $('#text').offset().top - 20  - $('#head').outerHeight()
         }, 500);
    });


});
